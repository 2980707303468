<template>
  <b-card>
    <b-row>
      <h6 class="section-label mx-1 mb-2">Customers</h6>
      <b-col cols="12" class="mb-2" v-if="rows">
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :select-options="{
            enabled: false,
            selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Action -->
            <span v-if="props.column.field === 'orders' && props.row.orders">
              {{ props.row.orders.length }}
            </span>
            <span v-else-if="props.column.field === 'name'"> {{ props.row.first_name }} {{ props.row.last_name }} </span>
            <span v-else-if="props.column.field === 'spent'">
              {{ spentTotal(props.row.orders) }}
            </span>
            <span v-else-if="props.column.field === 'updated_at'">
              {{ lastLogin(props.row.updated_at) }}
            </span>
            <span v-else-if="props.column.field === 'action'">
              <router-link :to="'/takeaway/shop/customers/' + props.row.id" block>
                <feather-icon icon="BookOpenIcon" class="mr-50" />
                <span>Preview</span>
              </router-link>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['500', '1000', '2500']"
                  class="mx-1"
                  @input="(value) => props.perPageChanged({ currentPerPage: value })"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import moment from "moment";
import { BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BRow, BCol } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";

import useJwt from "@/auth/jwt/useJwt";
import _ from "lodash";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BCol,
    BRow,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      pageLength: 500,
      dir: false,
      columns: [
        {
          label: "ID",
          field: "id",
          width: "80px",
        },
        {
          label: "Name",
          field: "name",
        },
        {
          label: "First Name",
          field: "first_name",
          hidden: true,
        },
        {
          label: "Last Name",
          field: "last_name",
          hidden: true,
        },
        {
          label: "Orders",
          field: "orders",
        },
        {
          label: "Spent",
          field: "spent",
        },
        {
          label: "Last Login",
          field: "updated_at",
        },
        {
          label: "E-Mail",
          field: "email",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: [],
      searchTerm: "",
    };
  },
  methods: {
    lastLogin(date) {
      return moment(date).format("HH:mm DD/MM/YYYY");
    },
    spentTotal(arr) {
      if (arr == undefined) {
        return "€0.00";
      }
      let spent = 0;
      for (let x = 0; x < arr.length; x++) {
        spent += arr[x].sub_total;
      }

      return "€" + spent.toFixed(2);
    },
  },
  created() {
    useJwt
      .get_all_customers()
      .then((response) => {
        localStorage.setItem("userCustomers", JSON.stringify(response.data.data));
        return (this.rows = response.data.data.reverse());
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Error`,
            icon: "CropIcon",
            variant: "danger",
            text: `We couldn't downlad your order data from the server.`,
          },
        });
      });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
